<template>
    <div style="width:100%">
        <div class="of-form-row">
            <el-form-item label="Instrument" prop="isin">
                <el-select 
                    v-model="formdata.isin"
                    @change="get_changed_isin_data">
                    <el-option
                        v-for="item in currency_list"
                        :key="item.code"
                        :label="item.code"
                        :value="item.code">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="Trade Currency" prop="tcur">
                <el-select 
                    v-model="formdata.tcur"
                    @change="get_changed_isin_data">
                    <el-option
                        v-for="item in currency_list"
                        :key="item.code"
                        :label="item.code"
                        :value="item.code">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="Price" prop="tpr">
                <el-input
                    v-model="formdata.tpr"
                    @blur="blur_format_field('tpr')">
                    <template slot="prepend">{{formdata.isin}}{{formdata.tcur}}</template> 
                </el-input>
            </el-form-item>

            <el-form-item label="Quantity" prop="q">
                <el-input
                    v-model="formdata.q"
                    @blur="blur_format_field('q')" />
            </el-form-item>

            <el-form-item label="Gross Amount" prop="gross_amount">
                <el-input
                    v-model="formdata.gross_amount"
                    @blur="blur_format_field('gross_amount')"
                    @change="change_gross_amount">
                    <template slot="prepend">{{formdata.tcur}}</template> 
                </el-input>
            </el-form-item>
            <el-form-item></el-form-item>
        </div>

        <div class="of-form-row" v-if="show_conversion_rate">
            <el-form-item label="Portfolio Conversion Rate" prop="tcur_prate">
                <el-input
                    v-model="formdata.tcur_prate"
                    @blur="blur_format_field('tcur_prate')">
                    <template slot="prepend">{{formdata.tcur}}{{portfolio_cur}}</template>    
                </el-input>
            </el-form-item>
            <el-form-item label="Portfolio Trade Amount" prop="tcur_prate_amount">
                <el-input
                    v-model="formdata.tcur_prate_amount"
                    @blur="blur_format_field('tcur_prate_amount')"
                    @change="change_tcur_prate_amount">
                    <template slot="prepend">{{portfolio_cur}}</template>    
                </el-input>
            </el-form-item>
            <el-form-item></el-form-item>
            <el-form-item></el-form-item>
            <el-form-item></el-form-item>
            <el-form-item></el-form-item>
        </div>
    </div>
</template>

<script>
import common from './operation_mixin'
import { num_to_money } from '@/utils'

export default {
    mixins: [common],

    data(){
        return {
            asset: 'cash',
        }
    },

    methods: {
        get_changed_isin_data(){
            if (!this.formdata.isin) return;
            
            this.isin_data = { isin: this.formdata.isin };
            let ident = this.formdata.isin + this.formdata.tcur
            this.get_history_values(ident, this.asset, ['price', 'date', 'name'], this.trade_date).then((response) => {
                if (!response) return;
                this.set_isin_data(response);
                if (response.price) 
                    this.$set(this.formdata, 'tpr', response.price);
                else if (this.formdata.isin && this.formdata.tcur && this.formdata.isin === this.formdata.tcur)
                    this.$set(this.formdata, 'tpr', 1)
            })
        },

        load_isin(isin){
            this.$set(this.formdata, 'isin', isin)
            this.get_changed_isin_data()
        },

        change_gross_amount(){
            let gross_amount = this.get_number_field('gross_amount')
            let q = this.get_number_field('q')
            if (q) this.$set(this.formdata, 'tpr', gross_amount / q);
            else this.$set(this.formdata, 'tpr', 0);
        },

        change_tcur_prate_amount(){
            let tcur_prate_amount = this.get_number_field('tcur_prate_amount')
            let gross_amount = this.get_number_field('gross_amount')
            if (tcur_prate_amount && gross_amount)
                this.$set(this.formdata, 'tcur_prate', tcur_prate_amount / gross_amount);
            else
                this.$set(this.formdata, 'tcur_prate', 0);
        },

        count_amounts(){
            let q = this.get_number_field('q')
            let tpr = this.get_number_field('tpr')
            let tcur_prate = this.get_number_field('tcur_prate')
            
            this.$set(this.formdata, 'gross_amount', q * tpr)
            this.$set(this.formdata, 'tcur_prate_amount', q * tpr * tcur_prate)
        },
    },

    watch: {
        portfolio_cur(val) {
            this.get_changed_isin_data();
        },
        trade_date(val){
            if (!this.operationid)
                this.get_changed_isin_data();
        },

        "formdata.tpr": function(val, old_val) {
            if (old_val === val) return;

            this.count_amounts()
        },

        "formdata.q": function(val, old_val) {
            if (old_val === val) return;

            this.count_amounts()
        },

        "formdata.tcur_prate": function(val, old_val) {
            if (old_val === val) return;
            
            let q = this.get_number_field('q')
            let tpr = this.get_number_field('tpr')
            let tcur_prate = this.get_number_field('tcur_prate')
            this.$set(this.formdata, 'tcur_prate_amount', q * tpr * tcur_prate)
        }
    }
}
</script>
